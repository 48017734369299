import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

export class RevealItems {

    constructor(breakpointMedium, breakpointLarge, breakpointXXLarge) {
        this.breakpointMedium = breakpointMedium;
        this.breakpointLarge = breakpointLarge;
        this.breakpointXXLarge = breakpointXXLarge;
    }
    init() {

        gsap.registerPlugin(ScrollTrigger)

        let elemsLeft = gsap.utils.toArray(".reveal-item.from-left")
        let startPoint = '100%'
        let delay = 0.1
        elemsLeft.forEach(elem => {
            gsap.set(elem, { x: -50, alpha: 0 })
            gsap.fromTo(elem, { x: -50, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        })
        let elemsRight = gsap.utils.toArray(".reveal-item.from-right")
        elemsRight.forEach(elem => {
            gsap.set(elem, { x: 50, alpha: 0 })
            gsap.fromTo(elem, { x: 50, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        })

        let elemsBottom = gsap.utils.toArray(".reveal-item.from-bottom")
        let dist = 100
        delay = 0
        if (window.innerWidth < this.breakpointMedium) {
            dist = 75
        } else {
            dist = 100
        }
        elemsBottom.forEach(elem => {
            gsap.set(elem, { y: dist, alpha: 0 })
            gsap.fromTo(elem, { y: dist, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: -dist + " " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    y: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        })

        ////////// Projektübersicht ////////

        let elemsBottomDelay = gsap.utils.toArray(".reveal-item.from-bottom-delay")
        delay = 0
        elemsBottomDelay.forEach(elem => {
            gsap.set(elem, { y: 0, alpha: 0 })
            if (ScrollTrigger.isInViewport(elem, 0)) {
                gsap.fromTo(elem, { y: 50, alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: "-100px " + startPoint,
                            //markers: true,
                            //scrub: true
                            //toggleActions: "restart none none none",
                            //onLeave: ({progress, direction, isActive}) => delay = 0.1
                        },
                        y: 0,
                        alpha: 1,
                        ease: "power4.out",
                        duration: 1.6,
                        delay: delay
                    })
                delay += 0.1
            } else {
                gsap.fromTo(elem, { y: 50, alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: "-100px " + startPoint,
                            //markers: true,
                            //scrub: true
                            //toggleActions: "restart none none none",
                            //onLeave: ({progress, direction, isActive}) => delay = 0.1
                        },
                        y: 0,
                        alpha: 1,
                        ease: "power4.out",
                        duration: 1.6,
                        delay: 0
                    })
            }
        })
    }
}