import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { ImgSlides } from "./js/imgslides.js";
import { RevealItems } from "./js/revealitems.js";

const breakpointMedium = 800;
const breakpointLarge = 1024;
const breakpointXXLarge = 1900;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium, breakpointLarge, breakpointXXLarge);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init();

    if (document.querySelectorAll('.img-swiper-container').length > 0) {
        const imgslides = new ImgSlides();
        imgslides.init();
    }
    if (document.body.classList.contains('single-projekte') || document.body.classList.contains('home')) {
        const revealitems = new RevealItems();
        revealitems.init();
    }
});