import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium, breakpointLarge, breakpointXXLarge) {
        this.breakpointMedium = breakpointMedium;
        this.breakpointLarge = breakpointLarge;
        this.breakpointXXLarge = breakpointXXLarge;
        this.anchorScroll = false;
    }
    init() {
        this.scrollToAnchorInit();
        this.scrollDown();
        this.setLogoSubline();
        this.moreBtnAni();
        this.downBtnAni();
        this.shrinkHeader();
        window.addEventListener('scroll', this.shrinkHeader.bind(this));
        window.addEventListener('resize', this.shrinkHeader.bind(this));
    }
    shrinkHeader() {
        const logoST = document.querySelector('.logo-subtxt');
        const headerWrapper = document.querySelector('.site-header');
        const mainMenu = document.querySelector('.mainmenu');
        let y = -60;
        let x = document.querySelector('.logo-main').getBoundingClientRect().width + 6;
        let h = 140;
        let hWrapper = 146;
        let hWrapperY = -20;
        let menuy = 0;
        if (window.innerWidth < this.breakpointXXLarge) {
            y = -40;
            h = 90;
            hWrapper = 146;
            menuy = 0;
            hWrapperY = -10;
        }
        if (window.innerWidth < this.breakpointLarge) {
            y = -32;
            h = 85;
            hWrapper = 116;
            menuy = 0;
        }
        if (window.innerWidth >= this.breakpointMedium) {
            if (window.scrollY > 100) {
                gsap.to(logoST, { y: y, x: x, duration: 0.3, ease: 'none' });
                //gsap.to(svgArchi.current, { scale: 0.6, duration: 0.3, ease: 'none' })
                gsap.to(headerWrapper, { height: h, y: hWrapperY, duration: 0.3, ease: 'none' });
                gsap.to(mainMenu, { y: menuy, duration: 0.3, ease: 'none' });
            } else {
                gsap.to(logoST, { y: 0, x: 0, scale: 1.0, duration: 0.3, ease: 'none' });
                gsap.to(headerWrapper, { height: hWrapper, y: 0, duration: 0.3, ease: 'none' });
                gsap.to(mainMenu, { y: 0, duration: 0.3, ease: 'none' });
            }
        }
    }
    moreBtnAni() {
        if (document.querySelectorAll('.morebtn-block').length > 0) {
            let x2 = 57;
            let x2L = x2 + 20;
            let transform1 = "translate(46.500000, 43.500000) rotate(-90.000000) translate(-46.500000, -43.500000)";
            let transform1L = "translate(46.500000, 43.500000) rotate(-90.000000) translate(-46.500000, -23.500000)";
            let transform2 = "translate(46.500000, 16.500000) scale(-1, 1) rotate(-270.000000) translate(-46.500000, -16.500000)";
            let transform2L = "translate(46.500000, 16.500000) scale(-1, 1) rotate(-270.000000) translate(-46.500000, 4.500000)";
            Array.from(document.querySelectorAll('.morebtn-block')).forEach(function (btn) {
                if (btn.querySelectorAll('a').length > 0) {
                    btn.querySelector('a').addEventListener('mouseover', function () {
                        //btn.querySelector('#Line').setAttribute('x2', x2L);
                        //gsap.to(btn.querySelector('#Line'), { duration: 0.4, ease: "none" });
                        gsap.to(btn.querySelector('#Line'), { attr: { x2: x2L }, duration: 0.2, ease: "none" });
                        //btn.querySelector('#Line-2').setAttribute('transform', transform1L);
                        //btn.querySelector('#Line-3').setAttribute('transform', transform2L);
                        gsap.to(btn.querySelector('#Line-2'), { attr: { transform: transform1L }, duration: 0.2, ease: "none" });
                        gsap.to(btn.querySelector('#Line-3'), { attr: { transform: transform2L }, duration: 0.2, ease: "none" });
                    });
                    btn.querySelector('a').addEventListener('mouseout', function () {
                        //btn.querySelector('#Line').setAttribute('x2', x2);
                        gsap.to(btn.querySelector('#Line'), { attr: { x2: x2 }, duration: 0.2, ease: "none" });
                        //btn.querySelector('#Line-2').setAttribute('transform', transform1);
                        //btn.querySelector('#Line-3').setAttribute('transform', transform2);
                        gsap.to(btn.querySelector('#Line-2'), { attr: { transform: transform1 }, duration: 0.2, ease: "none" });
                        gsap.to(btn.querySelector('#Line-3'), { attr: { transform: transform2 }, duration: 0.2, ease: "none" });
                    });
                }
            });
        }
    }
    downBtnAni() {
        if (document.querySelectorAll('.arrow-down').length > 0) {
            let x2 = 74;
            let x2L = x2 + 20;
            let transform1 = "translate(0, 0)";
            let transform1L = "translate(0, 20)";
            let transform2 = "translate(0, 0)";
            let transform2L = "translate(0, 20)";

            Array.from(document.querySelectorAll('.arrow-down')).forEach(function (btn) {
                btn.addEventListener('mouseover', function () {
                    gsap.to(btn.querySelector('#Line'), { attr: { y2: x2L }, duration: 0.2, ease: "none" });
                    gsap.to(btn.querySelector('#Line-2'), { attr: { transform: transform1L }, duration: 0.2, ease: "none" });
                    gsap.to(btn.querySelector('#Line-3'), { attr: { transform: transform2L }, duration: 0.2, ease: "none" });
                });
                btn.addEventListener('mouseout', function () {
                    gsap.to(btn.querySelector('#Line'), { attr: { y2: x2 }, duration: 0.2, ease: "none" });
                    gsap.to(btn.querySelector('#Line-2'), { attr: { transform: transform1 }, duration: 0.2, ease: "none" });
                    gsap.to(btn.querySelector('#Line-3'), { attr: { transform: transform2 }, duration: 0.2, ease: "none" });
                });
            });
        }
    }
    setLogoSubline() {
        const logotxt = document.querySelector('.logo-subtxt');
        logotxt.classList.add('show');
    }
    scrollDown() {
        if (document.querySelectorAll('.arrow-down').length > 0) {
            if (window.innerWidth >= this.breakpointMedium) {
                document.querySelector('.arrow-down').addEventListener('click', function () {
                    gsap.to(window, { duration: 1.4, scrollTo: window.innerHeight - 60, ease: "power3.out" });
                });
            } else {
                document.querySelector('.arrow-down').addEventListener('click', function () {
                    gsap.to(window, { duration: 1.4, scrollTo: window.innerHeight - 140, ease: "power3.out" });
                });
            }
        }
    }
    scrollToAnchorInit() {
        var self = this;
        //if (document.body.classList.contains('home')) {
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            anchor.addEventListener('click', function (e) {
                self.anchorScroll = true;
                self.scrollToAnchor(e, anchor.getAttribute('href'));
                e.preventDefault();
            });
        });
        //}
        if (window.location.hash) {
            //window.addEventListener('load', this.scrollToAnchor.bind(this));
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            let p = document.getElementById(a).getBoundingClientRect().top + window.pageYOffset - 30;
            gsap.to(window, { duration: 1.4, scrollTo: p, ease: "power3.out", onComplete: self.resetAnchorSCroll, onCompleteParams: [self] });
        }
    }
    resetAnchorSCroll(self) {
        self.anchorScroll = false;
    }
}